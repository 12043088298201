<template>
  <div class="home-view">
    <b-container>

      <PageHeader
        class="my-4"
        hideBackButton
      >
        Привязанные устройства
        <template v-slot:right>
          <b-link
            to="/bind-device"
            v-b-tooltip.hover
            title="Добавить устройство"
            class="button button--add-device"
          >
            <b-icon
              icon="plus-square-fill"
              font-scale="2"
            ></b-icon>
          </b-link>
        </template>
      </PageHeader>

      <p class="mb-5">
        Привязывайте свои устройства с помощью QR кода и получайте расширенную гарантию!
      </p>

      <BondedDevices/>
    </b-container>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import BondedDevices from '../components/BondedDevices.vue';

export default {
  name: 'Home',

  components: {
    PageHeader,
    BondedDevices,
  },
};
</script>

<style lang="scss">
.home-view {}
</style>
