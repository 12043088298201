import { axiosProfiClubApiWithCredentials } from '../index';

/* Получить привязанное устройство  */
export async function getProfileBondedDevice(bindId) {
  const urlSegment = `profile/warranty-details/${bindId}`;
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);

  return response.data ? response.data : null;
}
/* /Получить привязанное устройство  */
