<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="white"
    opacity="0.85"
    spinner-variant="primary"
  >
    <div class="bonded-devices-view">
      <!-- Ошибка -->
      <b-alert
        show
        v-if="serverErrorMessage"
        variant="danger"
      >
        {{ serverErrorMessage }}
      </b-alert>
      <!-- /Ошибка -->

      <!-- Нет операций -->
      <b-alert
        show
        v-if="!serverErrorMessage && bondedDevicesList.length < 1 && !isLoading"
        variant="info"
      >
        Устройства отсутствуют
      </b-alert>
      <!-- /Нет операций -->

      <!-- Карточки устройств -->
      <b-row>
        <b-col
          xs="12"
          lg="6"
          class="mb-5"
          v-for="(bd, index) in bondedDevicesList"
          :key="`bd_${index}`"
        >
          <Device
            :title="bd.deviceName"
            :serialNumber="bd.serialNumber"
            :bindId="bd.id"
            @isCertificateCreating="onCertificateCreated"
          />
        </b-col>
      </b-row>
      <!-- /Карточки устройств -->

      <!-- Пагинация -->
      <b-pagination
        v-if="!serverErrorMessage && bondedDevicesList.length > 0"
        aria-controls="journal-records-table"
        v-model="curPage"
        :per-page="perPage"
        :total-rows="allProfileBondedDevicesCount"
        align="center"
      ></b-pagination>
      <!-- /Пагинация -->
    </div>
  </b-overlay>
</template>

<script>
import Device from './Device.vue';

import {
  getProfileBondedDevices,
} from '../api/profi_club/bonded_devices';

export default {
  name: 'BondedDevices',

  components: {
    Device,
  },

  data() {
    return {
      isLoading: true,
      serverErrorMessage: null,
      bondedDevicesList: [],
      // Пагинация
      allProfileBondedDevicesCount: 0,
      perPage: 10,
      curPage: 1,
    };
  },
  watch: {
    curPage() {
      this.getProfileBondedDevicesWrap();
    },
  },
  async mounted() {
    await this.getProfileBondedDevicesWrap();
  },
  methods: {
    onCertificateCreated(resp) {
      this.isLoading = resp === true;
    },
    async getProfileBondedDevicesWrap() {
      this.serverErrorMessage = null;
      this.isLoading = true;
      try {
        const resp = await getProfileBondedDevices(this.curPage, this.perPage);
        this.bondedDevicesList = resp.devices;
        this.allProfileBondedDevicesCount = resp.count;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.serverErrorMessage = e;
      }
    },
  },
};
</script>
