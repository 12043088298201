<template>
  <div class="device p-4 h-100 d-flex flex-column align-items-start">
    <h5>{{ title }}</h5>
    <p
      v-if="serialNumber"
      class="device__serial-number"
    >
      Серийный №: {{ normalizedSerialNumber }}
    </p>
    <b-button
      class="mt-auto"
      @click="makeCertificate(bindId)"
      variant="primary"
      title="Скачать сертификат бонусной гарантии"
      size="sm"
    >
      Гарантийный талон
      <b-icon
        icon="file-earmark-check"
        aria-hidden="true"
      />
    </b-button>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';

import { getProfileBondedDevice } from '../api/profi_club/bonded_device';
import { formatSerialNumber } from '../utils';
import certificateBackground from '../assets/certificate/background.jpg';
import certificateLogo from '../assets/certificate/logo.png';
import certificateStamp from '../assets/certificate/stamp.png';
import certificateLogos from '../assets/certificate/logos.png';

export default {
  name: 'Device',

  data() {
    return {
      serverErrorMessage: null,
      isCertificateCreating: false,
    };
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    serialNumber: {
      type: String,
      default: null,
    },
    bindId: {
      type: String,
      default: null,
    },
  },
  computed: {
    normalizedSerialNumber() {
      if (!this.serialNumber) return '';
      return formatSerialNumber(this.serialNumber);
    },
  },
  methods: {
    async makeCertificate(bindId) {
      this.isCertificateCreating = true;
      this.$emit('isCertificateCreating', this.isCertificateCreating);
      const colorPrimary = '#1a2a56';
      const colorSecondary = '#3f97d9';
      const certificateText2 = ['Срок расширенной гарантии исчисляется с момента(даты) выпуска прибора.'];
      const certificateText3 = ['Гарантия предоставляется при соблюдении условий монтажа, эксплуатации и сервисного обслуживания, указанных в паспорте прибора.'];
      const year = new Date().getFullYear();
      try {
        const resp = await getProfileBondedDevice(bindId);
        // Серийный номер
        const sn = formatSerialNumber(resp.serialNumber);
        const extendedExpiration = (resp.warranty) ? resp.warranty.extendedExpiration : null;

        const extendedExpirationFromCnr = resp.cnr.warranty.extendedExpiration;

        const extendedExpirationYears = () => {
          if (extendedExpirationFromCnr !== null && extendedExpirationFromCnr !== 0) {
            const expirationYear = extendedExpirationFromCnr;
            return `Срок расширенной гарантии: ${expirationYear} лет`;
          }
          return '';
        };
        const extendedExpirationDate = () => {
          if (extendedExpiration !== null && extendedExpirationFromCnr !== 0) {
            const date = new Date(extendedExpiration);
            const expirationDay = String(date.getDate())
              .padStart(2, '0');
            const expirationMonth = String(date.getMonth() + 1)
              .padStart(2, '0');
            const expirationYear = String(date.getFullYear())
              .padStart(2, '0');
            return `${expirationDay}.${expirationMonth}.${expirationYear}`;
          }
          return '?';
        };
        /* eslint-disable new-cap */
        const certificate = new jsPDF();
        // TODO: Убрать магические значения

        // Подключаем шрифты
        certificate.addFont('fonts/Roboto-Italic.ttf', 'Roboto', 'normal-italic');
        certificate.addFont('fonts/Roboto-MediumItalic.ttf', 'Roboto', 'medium-italic');
        certificate.addFont('fonts/Roboto-Bold.ttf', 'Roboto', 'bold');

        // Фоновое изображение
        certificate.addImage(certificateBackground, 'JPEG', 0, 0, 210, 297, null, 'FAST');
        // Логотип
        certificate.addImage(certificateLogo, 'PNG', 135, 13.8, 60.07, 12.72, null, 'NONE');

        // Заголовок
        certificate.setTextColor(colorPrimary);
        certificate.setFont('Roboto', 'bold');
        certificate.setFontSize(30);
        certificate.text('Сертификат', 105, 50, { align: 'center' });

        // Текст
        certificate.setFont('Roboto', 'medium-italic');
        certificate.setFontSize(18);
        certificate.text(['расширенной гарантии', 'на прибор:'], 105, 60, { align: 'center' });

        // Наименование устройства
        certificate.setTextColor(colorSecondary);
        certificate.setFont('Roboto', 'bold');
        certificate.setFontSize(22);
        certificate.text([`${this.title}`], 105, 90, {
          align: 'center',
          maxWidth: 180,
        });

        // Серийный номер
        certificate.setTextColor(colorPrimary);
        certificate.setFontSize(17);
        certificate.text([`серийный номер: ${sn}`], 105, 120, {
          align: 'center',
          maxWidth: 180,
        });

        // Текст
        certificate.setFont('Roboto', 'medium-italic');
        certificate.setFontSize(14);
        if (extendedExpiration) {
          certificate.text(`${extendedExpirationYears()}`, 105, 130, {
            align: 'center',
            maxWidth: 180,
          });
        }
        certificate.text(certificateText2, 105, 140, {
          align: 'center',
          maxWidth: 180,
        });

        if (extendedExpiration && extendedExpirationFromCnr !== 0) {
          certificate.setTextColor(colorPrimary);
          certificate.setFontSize(17);
          certificate.text([`Дата окончания расширенной гарантии: ${extendedExpirationDate()}`],
            105, 150, {
              align: 'center',
              maxWidth: 180,
            });
        }

        certificate.setFont('Roboto', 'medium-italic');
        certificate.setFontSize(14);
        certificate.text(certificateText3, 105, 180, {
          align: 'center',
          maxWidth: 120,
        });

        // Печать
        certificate.addImage(certificateStamp, 'PNG', 157, 190, 38.16, 38.16, null, 'NONE');

        // Подпись под печатью
        certificate.setFontSize(15);
        certificate.text([`«Бастион», ${year} г.`], 195, 235, { align: 'right' });

        // Логотипы
        certificate.addImage(certificateLogos, 'PNG', 16, 273, 178.45, 18.73, null, 'NONE');

        // Открытие сертификата в новом окне
        window.open(URL.createObjectURL(certificate.output('blob')));
      } catch (e) {
        this.$bvToast.toast(
          `${e.response.data}`,
          {
            title: 'Ошибка',
            toaster: 'b-toaster-bottom-left',
            variant: 'danger',
            autoHideDelay: 10000,
          },
        );
        this.serverErrorMessage = e;
      } finally {
        this.isCertificateCreating = false;
        this.$emit('isCertificateCreating', this.isCertificateCreating);
      }
    },
  },
};
</script>

<style lang="scss">
.device {
  background-color: #F2F2F2;
  border-radius: 10px;

  &__serial-number {
    font-weight: 300;
    font-size: 0.85em;
  }
}
</style>
